export const adlibs = [
  "Without doubt # has left its # with us as some of the most # # on the #... This # that we highly recommend for # # and high-end #.",
  `Be kind to your #-footed #
  For a duck may be somebody's #,
  Be kind to your # in #
  Where the weather is always #.
  
  You may think that this is the #,
  Well it is.`,
  `My "Dream Man" should, first of all be very # and #. He should have a physique like #, a profile like #, and the intelligence of a/an #. He must be polite and must always remember to # my #, to tip his # and to take my # when crossing the street. He should move #, have a/an # voice, and should always dress #. I would also like him to be a/an # dancer, and when we are alone he should whisper # nothings into my # and hold my # #. I know a/an # is hard to find. In fact the only one I can think of is #`,
  `# # will not be attending school today. He/she has come down with a case of # and has horrible # and a/an # fever. We have made an appointment with the # Dr. #, who studied for many years in # and has # degrees in pediatrics. He will send you all the information you need. Thank you!
  Sincerely
  Mrs. #.`,
  `Come # at WALMART, where you'll receive # discounts on all of your favorite brand name #. Our # and # associates are there to # you # hours a day. Here you will find # prices on the # you need. # for the moms, # for the kids and all the latest electronics for the #. So come on down to your # # WALMART where the # come first.`,
  `The # Dragon is the # Dragon of all. It has # #, and a # shaped like a #. It loves to eat #, although it will feast on nearly anything. It is # and #. You must be # around it, or you may end up as it's meal!`,
  `- # # without being #.
  - The Universe does not have # -- it has #, and # can be #.
  - The # of random # is too important to be left to chance.
  - You can build a # with #, but you can't sit on it for long.`,
];
