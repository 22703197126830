export enum MiningStatus {
  WAITING_TO_START,
  STARTED,
  WAITING_TO_STOP,
  STOPPED,
}

export enum TxStatus {
  INITIATED,
  SUCCESS,
  FAILED,
  ATTEMPTING,
}

export enum BountyClaimStatus {
  INITIATED,
  SUCCESS,
  FAILED,
  NOT_OWNER,
}

export enum ConnectionStatus {
  INITIATED,
  SUCCESS,
  FAILED,
  CONNECTING,
}
