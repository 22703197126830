import React from 'react';
import ReactDOM from 'react-dom';
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import dotenv from 'dotenv'
dotenv.config()


function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  return library;
}

ReactDOM.render(
  <React.StrictMode>
     <Web3ReactProvider getLibrary={getLibrary}>
      <App />
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
